/**
 * 根据路径获取对象的值
 * @param obj - 目标对象
 * @param path - 路径字符串，例如 "articleSection.new"
 * @returns 根据路径获取的值，如果路径不存在，则返回 undefined
 */
export function getValueByPath(obj: any, path: string): any {
  const parts = path.split('.')
  let current = obj
  for (const part of parts) {
    if (current.hasOwnProperty(part)) {
      current = current[part]
    } else {
      return undefined
    }
  }
  return current
}
