import { dataReadyAwait } from './awaiter'
import { getAutoFetch, getPageId } from './biz-meta'
import CmsDataAdaptor from './cms-data-adaptor'
import { getCurrentApiActions, IAPI, PLUGIN_NAME_SPACE } from './constants'
import { FunLinkSdkStandardApi } from './interfaces/fun-link-sdk-standard-api'
import { log } from './logs'
import { merge } from './object-mapper'
import { ILayerDataOption, IPaginationLayerDataOption, service } from './service'
import { getValueByPath } from './utils/get-value-by-path'
import { wrapResErrorStruct, wrapResStruct } from './utils/wrap-service-struct'

/**
 * 配置文件
 */
export interface FunLinkSdkConfig {
  /**
   * 页面 id
   */
  pageId: string | null | undefined
  /**
   * 入口 api
   */
  appEnv: string | null | undefined
  /**
   * 禁用自动请求数据
   */
  autoFetch: boolean
}

/**
 * 标准响应结构接口
 */
export interface IResponse<T = any> {
  /**
   * 响应状态码
   * @remarks 0 表示成功，非0表示错误
   */
  status: number
  /**
   * 响应数据
   */
  data: T
  /**
   * 响应消息
   * @remarks 成功时通常为空字符串
   */
  msg: string
}

/**
 * common 通用 layer
 * pagination 分页数据 layer
 * content 内容 layer
 */
export type LayerType = 'common' | 'pagination' | 'content'

/**
 * layer 类型
 */
const LAYER_TYPE = {
  //  通用 layer
  common: 'common',
  //  分页数据 layer
  pagination: 'pagination',
  // 内容 layer
  content: 'content',
} as const

/**
 * 低代码连接器
 */
export class FunLinkSdk implements FunLinkSdkStandardApi {
  apiActions: IAPI

  private validateSdkConfig() {
    const pageId = getPageId()
    if (!pageId) {
      throw new Error('[fun link sdk]: pageId 缺失')
    }
  }

  constructor() {
    window.FUN_LINK_SDK = this
    this.validateSdkConfig()
    this.apiActions = getCurrentApiActions()
  }

  /**
   * 启动入口
   */
  public bootstrap() {
    const autoFetch = getAutoFetch()
    if (autoFetch) {
      window[this.getGlobalPromiseName()] = new Promise(async (resolve, reject) => {
        this.getPageData().then(resolve).catch(reject)
      })
    }
  }

  /**
   * 请求获取页面数据
   */
  public async getPageData(layerDataOptions?: ILayerDataOption[]) {
    try {
      const pageId = getPageId()
      if (!pageId) {
        throw new Error('[fun link sdk]: pageId 缺失')
      }
      const { layers, setting } = await service.getPageData({ pageId, layers: layerDataOptions })
      const pageData = this.getDestinationPageData(layers)
      dataReadyAwait.resolve({ pageData, setting })
      return wrapResStruct(pageData)
    } catch (error: any) {
      dataReadyAwait.reject(error)
      throw error
    }
  }

  /**
   * 获取 getLayersData
   * @param layerDataOptions
   */
  public async getLayersData(layerDataOptions: ILayerDataOption[]) {
    const pageId = getPageId()

    if (!pageId) {
      throw new Error('[fun link sdk]: pageId 缺失')
    }

    return service.getLayersData({ pageId, layerDataOptions }).then((data) => {
      return wrapResStruct(this.getDestinationLayersData(data.layers))
    })
  }

  /**
   * 获取分页数据
   * @param layerId - 层ID
   * @param param - 分页参数
   * @returns Promise<IResponse<{list: any[], total: number}>>
   */
  public async getLayerData(
    layerId: string,
    param?: IPaginationLayerDataOption,
  ): Promise<IResponse<{ list: any[]; total: number }>> {
    const pageId = getPageId()

    if (!pageId) {
      throw new Error('[fun link sdk]: pageId 缺失')
    }

    /**
     * 默认分页参数
     */
    param = param ? { ...param, page: param.page || 1, perPage: param.perPage || 10 } : { page: 1, perPage: 10 }

    /**
     * 构造层级数据选项数组
     * @param layerId - 层ID
     * @param param - 分页参数
     */
    const layerDataOptions: ILayerDataOption[] = [{ layerId, type: 'pagination', param }]
    /**
     * 调用服务获取层级数据
     * @param pageId - 页面ID
     * @param layerDataOptions - 层级数据选项
     * @returns Promise<IResponse<{list: any[], total: number}>>
     */
    return service.getLayersData({ pageId, layerDataOptions }).then((data) => {
      try {
        /**
         * 获取第一个层级的所有位置
         */
        const positions = data.layers[0].positions || []
        /**
         * 检查是否有多个分发位置场景
         */
        if (positions.length > 1) {
          throw new Error('禁止超过一个分发位置场景')
        }
        /**
         * 获取第一个位置的所有数据源
         */
        const datasources = positions?.[0]?.datasources || []
        /**
         * 检查是否有多个数据源场景
         */
        if (datasources.length > 1) {
          throw new Error('禁止超过一个数据源场景')
        }
        /**
         * 检查是否有数据源
         */
        if (!datasources[0]) {
          throw new Error('无法找到数据源, 可能没有绑定数据源')
        }
        /**
         * 获取数据源的总数
         */
        const total = datasources[0]?.total
        /**
         * 获取数据源的当前页数
         */
        const page = datasources[0]?.page
        /**
         * 获取数据源的每页显示数量
         */
        const perPage = datasources[0]?.perPage
        /**
         * 获取目标层级数据
         */
        const layerData = this.getDestinationLayersData(data.layers)
        /**
         * 根据层ID获取数据列表
         */
        const list = getValueByPath(layerData, layerId)
        /**
         * 包装返回结构
         */
        return wrapResStruct({ list, total, page, perPage })
      } catch (e: any) {
        /**
         * 包装错误返回结构
         */
        return wrapResErrorStruct(e.message)
      }
    })
  }

  /**
   * 获取 getLayersData
   * @param pluginName - 插件名称
   */
  public async getPluginData(pluginName: string) {
    const pageId = getPageId()

    if (!pageId) {
      throw new Error('[fun link sdk]: pageId 缺失')
    }

    const pageDataPromise = window[this.getGlobalPromiseName()]

    if (!pageDataPromise) {
      throw new Error('[fun link sdk]: 请先调用 bootstrap 方法')
    }

    return pageDataPromise.then((pageData) => {
      return wrapResStruct(pageData.data[PLUGIN_NAME_SPACE][pluginName])
    })
  }

  /**
   * 获取映射前的数据
   * @param layer
   * @param layerType
   * @param mapper
   * @private
   */
  private getMapperSourceData(
    layer: any,
    layerType: LayerType,
    mapper?: {
      datasourceId: string
      positionId: string
    },
  ) {
    if (layerType === LAYER_TYPE.pagination || layerType === LAYER_TYPE.content) {
      return layer.data
    }
    const positionData = layer.positions.find((position: any) => position.positionId === mapper?.positionId)
    const foundResult = (positionData.datasources || []).find(
      (datasource: any) => datasource.datasourceId === mapper?.datasourceId,
    )
    return foundResult?.data || {}
  }

  /**
   * 清理空对象
   * @param obj
   * @returns
   * @private
   */
  private cleanEmptyObjects(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.cleanEmptyObjects(item)).filter((item) => Object.keys(item).length > 0)
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach((key) => {
        obj[key] = this.cleanEmptyObjects(obj[key])
      })
    }
    return obj
  }

  /**
   * 将layer数据进行数据映射转换为目标数据
   * @param layer
   * @param layerType
   * @private
   */
  private getDestinationLayerData(layer: any, layerType: LayerType = LAYER_TYPE.common) {
    let layerDestinationData = {}
    const variableBindData = layer.variableBinds || []
    variableBindData.forEach((variableBindItem: any) => {
      if (
        variableBindItem.key &&
        variableBindItem.mapper &&
        variableBindItem.mapper.positionId &&
        variableBindItem.mapper.datasourceId &&
        variableBindItem.mapper.key
      ) {
        const foundMapperSourceData = this.getMapperSourceData(layer, layerType, variableBindItem.mapper)
        const mapperObject = {
          // [`${Array.isArray(foundMapperSourceData) ? '[].' : ''}${variableBindItem.mapper.key}`]: variableBindItem.key
          [`${Array.isArray(foundMapperSourceData) ? '[].' : ''}${variableBindItem.mapper.key}`]: {
            key: variableBindItem.key,
            transform: (value: any) => {
              return CmsDataAdaptor.adapterToTemplateData(variableBindItem.mapper.valueType, value)
            },
          },
        }
        merge(foundMapperSourceData, layerDestinationData, mapperObject)
        if (typeof foundMapperSourceData === 'object' && Object.keys(foundMapperSourceData).length === 0) {
          layerDestinationData = this.cleanEmptyObjects(layerDestinationData)
        }
      }
    })
    return layerDestinationData
  }

  /**
   * 获取顶层layer数据
   * @param layers
   * @private
   */
  private filterTopLayerData(layers: any[] = []) {
    return layers.filter((layer) => layer.topLayer === true || (layer.layerId || '').split('.').length === 1)
  }

  /**
   * 将页面数据进行数据映射转换为目标数据
   * @param layers
   * @private
   */
  private getDestinationPageData(layers: any[] = []) {
    let pageDestinationData = {}
    this.filterTopLayerData(layers || []).forEach((layer) => {
      const layerData = this.getDestinationLayerData(layer)
      pageDestinationData = {
        ...pageDestinationData,
        ...(layerData || {}),
      }
    })
    log.debug('pageDestinationData', pageDestinationData)
    return pageDestinationData
  }

  /**
   * 将多个layer进行数据映射转换为目标数据
   * @param layers
   * @private
   */
  private getDestinationLayersData(layers: any[] = []) {
    let layersDestinationData = {}
    layers.forEach((layer) => {
      const layerData = this.getDestinationLayerData(layer)
      layersDestinationData = {
        ...layersDestinationData,
        ...(layerData || {}),
      }
    })
    log.debug('layersDestinationData', layersDestinationData)
    return layersDestinationData
  }

  /**
   * 获取 html 中 meta attribute
   * @param attributeName
   * @private
   */
  private getAttributeFormHtml(attributeName: string) {
    return (document.querySelector(`meta[name=${attributeName}]`) as HTMLMetaElement)?.content
  }

  /**
   * 获取 cms 请求 promise 名称
   * @private
   */
  private getGlobalPromiseName() {
    const attributeName = 'data-fun-link-global-promise-name'
    return this.getAttributeFormHtml(attributeName) || '__FUN_LINK_PAGE_DATA_PROMISE__'
  }
}
