import { IRequest } from '../request/interface'
import { IErrorReport } from '../error-report/interface'
import type { PluginManager } from '../plugin'

/**
 * public 公共空间
 */
const varSpace = (window.fl = window.fl || {})

/**
 * 注册公共变量
 * @param name - 公共变量名
 * @param value - 公共变量值
 */
const registerPublicVar = (name: string, value: any): void => {
  ;(varSpace as any)[name] = value
}

/**
 * 注册 ready 公共变量
 * @param promise - Promise 对象，用于指示 ready 状态
 */
export const registerReadyPublicVar = (promise: Promise<void>): void => {
  registerPublicVar('ready', promise)
}

/**
 * 注册 request 公共变量
 * @param request - IRequest 对象，用于发送 HTTP 请求
 */
export const registerRequestPublicVar = (request: IRequest): void => {
  registerPublicVar('request', request)
}

/**
 * 注册 sentryErrorReport 公共变量
 * @param errorReport - IErrorReport 对象，用于错误上报
 */
export const registerSentryErrorReportPublicVar = (errorReport: IErrorReport): void => {
  registerPublicVar('errorReport', errorReport)
}

/**
 * 注册 plugin 公共变量
 * @param plugin - PluginManager 实例，用于管理插件
 */
export const registerPluginPublicVar = (plugin: PluginManager): void => {
  registerPublicVar('plugin', plugin)
}

/**
 * 注册 dataReady 公共变量
 * @param dataReady - 数据准备完成
 */
export const registerDataReadyPublicVar = (dataReady: any): void => {
  registerPublicVar('dataReady', dataReady)
}

/**
 * 注册 overseaReportPromise 公共变量
 * @param overseaReportPromise - 海外埋点上报Promise
 */
export const registerOverseaReportPromisePublicVar = <T>(overseaReportPromise: Promise<T>): void => {
  registerPublicVar('overseaReportPromise', overseaReportPromise)
}

/**
 * 注册 stReportPromise 公共变量
 * @param stReportPromise - 国内埋点上报Promise
 */
export const registerStReportPromisePublicVar = <T>(stReportPromise: Promise<T>): void => {
  registerPublicVar('stReportPromise', stReportPromise)
}

/**
 * 注册 versionEnv 公共变量
 * @param versionEnv - 获取版本环境信息
 */
export const registerVersionAppEnvPublicVar = (versionEnv: any): void => {
  registerPublicVar('versionEnv', versionEnv)
}
