import { IResponse } from '../fun-link-sdk'

/**
 * 包装响应结构
 * @param data - 响应数据
 * @returns 包装后的响应结构
 */
export function wrapResStruct(data: any): IResponse {
  return {
    status: 0,
    data: data,
    msg: '',
  }
}

/**
 * 包装错误响应结构
 * @param msg - 错误消息
 * @returns 包装后的错误响应结构
 */
export function wrapResErrorStruct(msg: string): IResponse {
  return {
    status: -1,
    data: null,
    msg,
  }
}
