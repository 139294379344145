import { queryMeta } from '../utils/query-meta'

/**
 * 从 URL 中获取查询参数的值
 * @param key - 要获取的查询参数的键
 * @returns 查询参数的值，如果不存在则返回空字符串
 */
export function getQueryValue(key: string): string {
  const params = new URLSearchParams(window.location.search)
  return params.get(key) || ''
}

/**
 * 检查水印功能是否启用
 * @returns 如果水印功能启用则返回 true，否则返回 false
 */
export function isWatermarkEnabled(): boolean {
  const enableWatermarkParam = getQueryValue('enable-watermark')
  return enableWatermarkParam.toLowerCase() === '1'
}

/**
 * 获取应用环境, 这里的环境主要是发布的版本并非环境
 * @returns {string | null} 如果找到了对应的URL参数，则返回其值，否则返回null
 */
export const getAppEnv: () => string | null = (function () {
  let appEnv: string | null
  return () => {
    if (appEnv === undefined) {
      appEnv = getQueryValue('funLinkAppEnv') || null
    }
    return appEnv
  }
})()

/**
 * 获取editor环境, 这里代指的是当前发布版本 env
 * @returns {string | null} 如果找到了对应的meta或URL参数，则返回其值，否则返回null
 */
export const getEditorEnv: () => string | null = (function () {
  let appEnv: string | null
  return () => {
    if (appEnv === undefined) {
      appEnv = queryMeta('funLinkAppEnv') || getQueryValue('funLinkEditorEnv') || null
    }
    return appEnv
  }
})()

/**
 * 获取调试模式状态
 * @returns {boolean} 如果 URL 参数中 'funlink-debug-mode' 的值为 '1'，则返回 true，否则返回 false
 */
export function getDebugModeViaUrlParams(): boolean {
  const debugMode = getQueryValue('funlink-debug-mode').toLowerCase()
  return debugMode === '1' || debugMode === 'true'
}

/**
 * 获取是否为GP环境
 * @returns {boolean} 如果URL参数中'gp'的值为'1'，则返回true，否则返回false
 */
export function getIsGpViaUrlParams(): boolean {
  const isGp = getQueryValue('gp')
  return isGp === '1'
}

/**
 * 获取认证码
 * @see https://365.kdocs.cn/l/cdtoXdxw6WNJ?from=koa
 * @returns {string | null} 如果找到了对应的URL参数，则返回其值，否则返回null
 */
export function getAuthCode(): string | null {
  return getQueryValue('auth_code') || null
}

/**
 * 移除 url 中的 auth_code 参数
 * @description 使用 history.replaceState 移除 auth_code 参数,不刷新页面
 */
export function removeAuthCodeFromUrl(): void {
  const url = new URL(window.location.href)
  url.searchParams.delete('auth_code')
  window.history.replaceState({}, '', url.toString())
}

/**
 * 获取内容ID
 * @returns {string | null} 如果找到了对应的URL参数，则返回其值，否则返回null
 */
export function getContentIdFromUrl(): string | null {
  return getQueryValue('contentId') || null
}
